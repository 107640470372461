import './styles/client.css';
import './client/js/bootstrap.min.js';
import './client/js/owl.carousel.min.js';
import './client/js/jquery.counterup.min.js';
import './client/js/waypoints.min.js';
import './client/venobox/venobox.js';
import './client/js/animated-text.js';
import './client/venobox/venobox.min.js';

//import 'isotope-packery';
import './client/js/jquery.meanmenu.js';
import './client/js/jquery.scrollUp.js';
import './client/js/jquery.barfiller.js';
import './client/js/popper.min.js';

import './client/js/theme.js';

$(function () {

	var jQueryBridget = require('jquery-bridget');
	var Isotope = require('isotope-layout');
	// make Isotope a jQuery plugin
	jQueryBridget('isotope', Isotope, $);

	new WOW({live: false}).init();

	// counterUp
	$('.counter').counterUp({
		delay: 10,
		time: 1000
	});

	$('.aa').counterUp({
		delay: 10,
		time: 1000
	});

	/* Portfolio Isotope  */
	$('.image_load').imagesLoaded(function () {

		if ($.fn.isotope) {

			var $portfolio = $('.image_load');
			$portfolio.isotope({
				itemSelector: '.grid-item',
				filter: '*',
				resizesContainer: true,
				layoutMode: 'masonry',
				transitionDuration: '0.8s'
			});

			$('.menu-filtering li').on('click', function () {
				$('.menu-filtering li').removeClass('current_menu_item');
				$(this).addClass('current_menu_item');
				var selector = $(this).attr('data-filter');
				$portfolio.isotope({
					filter: selector
				});
			});
		}
	});

	// Case Study Active
	$('.case-study').owlCarousel({
		loop: true,
		autoplay: true,
		smartSpeed: 2000,
		autoplayTimeout: 1500,
		dots: false,
		nav: true,
		center: true,
		navText: ["<i class='bi bi-chevron-left''></i>", "<i class='bi bi-chevron-right''></i>"],
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1000: {
				items: 3
			},
			1920: {
				items: 3
			}
		}
	})

	// service Active
	$('.testi_list').owlCarousel({
		loop: true,
		autoplay: true,
		smartSpeed: 1500,
		autoplayTimeout: 10000,
		dots: false,
		nav: true,
		center: true,
		navText: ["<i class='bi bi-chevron-left''></i>", "<i class='bi bi-chevron-right''></i>"],
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 1
			},
			992: {
				items: 1
			},
			1000: {
				items: 1
			},
			1920: {
				items: 1
			}
		}
	})

	$('.relation_list').owlCarousel({
		loop: true,
		autoplay: true,
		smartSpeed: 1000,
		autoplayTimeout: 5000,
		dots: false,
		nav: true,
		center: true,
		navText: ["<i class='bi bi-chevron-left''></i>", "<i class='bi bi-chevron-right''></i>"],
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 1
			},
			992: {
				items: 1
			},
			1000: {
				items: 1
			},
			1920: {
				items: 1
			}
		}
	})

	$('.case-study-who-we-are-benefits-list').owlCarousel({
		loop: true,
		autoplay: true,
		smartSpeed: 1000,
		autoplayTimeout: 5000,
		dots: false,
		nav: true,
		center: true,
		navText: ["<i class='bi bi-chevron-left''></i>", "<i class='bi bi-chevron-right''></i>"],
		responsive: {
			0: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 3
			},
			1000: {
				items: 3
			},
			1920: {
				items: 3
			}
		}
	})

	$('.meanmenu-reveal').attr('id', 'hamburger');

	if($('#contact_form').length) {
		$('#contact_form').on('submit', function() {
			$("#overlay").fadeIn(300);
		})
	}

})
